/* Base styles for text elements */
.container-padding {
  height: 100vh !important;
}
.contact-us-header-text,
.contact-us-sub-header-text {
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  color: #fff !important;
}

.contact-us-sub-header-text {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0%;
  text-transform: uppercase;
}

.contact-us-header-text {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 1%;
  vertical-align: middle;
}

.contact-us-text {
  display: flex;
  flex-direction: column;
  /* padding-top: 3%; */
  margin-left: 288.55px;
}

.contact-us-item-title-text {
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  color: #fff !important;
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  letter-spacing: -1%;
}

.contact-us-item-description-text {
  margin-bottom: 0.3rem;
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  letter-spacing: -1%;
}

.contact-us-grid {
  display: grid;
  align-items: center !important;
  gap: 3rem;
  grid-template-columns: 50% 50%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-us-text {
    margin-left: 100px;
  }
}

@media (max-width: 576px) {
  .contact-us-item-title-text {
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    color: #fff !important;
    font-family: "Abhaya Libre" !important;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: -1%;
  }

  .contact-us-item-description-text {
    margin-bottom: 0.3rem;
    color: #fff !important;
    font-family: "Poppins" !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    letter-spacing: -1%;
  }

  .contact-us-grid {
    margin-top: 3rem;
    display: grid;
    gap: 3rem;
    grid-template-columns: 100%;
  }

  .contact-us-text {
    margin-left: 20px;
    margin-right: 20px;
  }

  .contact-us-form {
    width: 90%;
  }

  .contact-us-img-container {
    /* padding-right: 288.55px; */
    padding-right: 0px !important;
    padding-left: 0px;
    background-color: #fff;
  }
}
