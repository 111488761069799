/* Responsive grid for different screen sizes */
.container-padding {
  padding: 0px 0px !important;
}

.about-us-grid {
  display: grid;
  align-items: center;
  margin-top: 1rem;
  gap: 3rem;
  grid-template-columns: 50% 50%;
}

.sub-header-text {
  font-family: "Abhaya Libre", serif !important;
  font-weight: 800;
  font-size: clamp(24px, 4vw, 24px) !important;
  line-height: 32px;
  text-transform: none !important;
}

.about-us-header-text {
  font-family: "Abhaya Libre", serif !important;
  font-weight: 800 !important;
  /* font-size: 48px !important; */
  font-size: clamp(36px, 4vw, 48px) !important;
  line-height: 45px;
  letter-spacing: 0%;
  vertical-align: middle;
  text-transform: none !important;
}

.about-us-item-title-text {
  margin: 1.5rem 0rem !important;
  color: #510000 !important;
  font-family: "Abhaya Libre", serif !important;
  font-weight: 700;
  font-size: clamp(14px, 4vw, 32px) !important;
  /* font-size: 32px; */
  line-height: 16px;
  letter-spacing: 0%;
  vertical-align: middle;
}

.description-text {
  font-family: "Poppins", serif !important;
  font-weight: 400;
  font-size: clamp(14px, 4vw, 16px);
  line-height: clamp(18px, 4vw, 24px);
  letter-spacing: "1%";
  text-transform: none !important;
}

@media (max-width: 576px) {
  .about-us-grid {
    gap: 0rem !important;
    grid-template-columns: 100%;
  }

  .about-us-item-title-text {
    margin: 1rem 0rem !important;
  }
}
