.title-text {
  font-family: "Abhaya Libre", serif !important;
  font-size: clamp(50px, 8vw, 72px) !important;
  font-weight: 800;
  line-height: clamp(49px, 1.125, 1.125) !important;
  text-align: left;
}

.sub-title-text-container {
  padding-top: clamp(0.5rem, 4vw, 0.2rem) !important;
}

.sub-title-text {
  font-family: "Poppins", serif !important;
  font-weight: 400 !important;
  font-size: clamp(12px, 4vw, 16px) !important;
  line-height: 1.875;
  color: #fff !important;
  text-align: left;
}

.buy-now-btn {
  border-radius: 60px;
  font-family: "Poppins", serif !important;
  color: #510000;
  background-color: #fff;
  padding: 0.5rem 2rem;
  font-size: clamp(14px, 4vw, 16px);
  font-weight: 500;
  text-align: center;
}
