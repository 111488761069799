/* Responsive grid for different screen sizes */
.logo-container {
  display: grid !important;
  gap: 0rem !important;
  grid-template-columns: 50% 50%;
}

.social-icon-container {
  text-align: right !important;
}

.nav-link-container {
  margin-top: 2rem;
  display: "flex";
  flex-direction: "row";
  justify-content: "center";
  text-align: center;
}
.nav-link-item {
  margin: 0rem 1rem;
  color: #510000;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0%;
  vertical-align: middle;
  cursor: pointer;
}

.rights-container {
  background-color: #510000;
  padding: 1.5rem 0rem;
  margin-top: 2rem;
  display: "flex";
  flex-direction: "row";
  justify-content: "center";
  text-align: center;
}
.rights-item {
  margin: 0rem 1rem;
  color: #fff;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0%;
  vertical-align: middle;
  cursor: pointer;
}
@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .social-icon {
    width: 10%;
  }

  .logo-container {
    gap: 1rem !important;
    grid-template-columns: 100%;
  }

  .footer-logo {
    text-align: center;
    width: 282px;
    height: 66px;
    top: 64px;
    left: 40px;
  }

  .social-icon-container {
    text-align: left !important;
  }

  .nav-link-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  .nav-link-item {
    margin: 1rem 0rem;
  }
}
