/* Responsive grid for different screen sizes */
.container-padding {
  padding: 100px 0px !important;
}

.our-commitment-grid {
  display: grid;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
  grid-template-columns: 30% 70%;
}

.our-services-sub-title-text {
  color: #0a0a0a;
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0%;
  vertical-align: middle;
}

.header-title-text {
  color: #0a0a0a !important;
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: clamp(36px, 4vw, 48px) !important;
  line-height: clamp(37px, 4vw, 60px) !important;
  text-transform: none !important;
  margin-top: 1rem !important;
}

.header-description {
  color: #0a0a0a !important;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: clamp(14px, 4vw, 16px) !important;
  line-height: clamp(20px, 4vw, 24px) !important;
  letter-spacing: 1%;
  text-align: center;
  vertical-align: middle;
}

.item-icon {
  width: 35px !important;
  height: 35px !important;
}

.item-title {
  color: #510000 !important;
  font-family: "Abhaya Libre" !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
  margin: 0.8rem 0rem;
}

.item-description {
  color: #000 !important;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 22px;
  letter-spacing: 0%;
  margin-bottom: 1.5rem !important;
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .container-padding {
    padding: 50px 0px !important;
  }

  .img {
    height: 491px !important;
  }

  .our-commitment-grid {
    grid-template-columns: 100%;
  }
}
