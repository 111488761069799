.motto {
  padding: 0px !important;
  margin: 0px !important;
  color: #510000 !important;
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: clamp(16px, 5vw, 24px) !important;
  line-height: 45px;
  letter-spacing: 0%;
  vertical-align: middle;
  text-align: center;
}
.nav-link-style {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: clamp(14px, 5vw, 16px) !important;
}
