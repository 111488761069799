.container-padding {
  padding: 100px 0px !important;
}

.header-text {
  font-family: "Abhaya Libre" !important;
  font-size: clamp(32px, 8vw, 48px) !important;
  font-weight: 800 !important;
  line-height: 1.2;
}

.listing-title-2 {
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: clamp(20px, 5vw, 24px) !important;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  /* margin-bottom: 0.5rem; */
}

.listing-description {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: clamp(12px, 4vw, 14px) !important;
  line-height: 25px;
  margin-bottom: 0.3rem !important;
}

.listing-price {
  color: #510000 !important;
  font-family: "Abhaya Libre" !important;
  font-weight: 800 !important;
  font-size: clamp(18px, 5vw, 20px) !important;
  line-height: 100%;
  letter-spacing: 0%;
  vertical-align: middle;
  margin-bottom: 0.3rem !important;
}

.listing-view-more {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: clamp(12px, 4vw, 12px);
  margin-bottom: 0.5rem;
}

/*  */
/* Add this to your CSS file */
.property-slider-container {
  width: 100%;
  margin: 0 auto;
}

.property-slider-container .slick-track {
  display: flex;
  gap: 0;
}

.property-slider-container .slick-slide {
  height: auto; /* Equal height slides */
}

.property-slide {
  height: 100%;
}

/* Make sure arrows are visible on the sides */
.property-slider-container .slick-prev, 
.property-slider-container .slick-next {
  z-index: 1;
}

.property-slider-container .slick-prev {
  left: 10px;
}

.property-slider-container .slick-next {
  right: 10px;
}

@media (max-width: 576px) {
  .container-padding {
    padding: 50px 0px !important;
  }
}
