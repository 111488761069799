/* Responsive grid for different screen sizes */
.marginTop {
  margin-top: 5rem;
}

.affilate-program-section {
  display: grid;
  margin-top: 1rem;
  gap: 0.5rem;
  grid-template-columns: 50% 50%;
}

.affiliate-program-header-text {
  font-family: "Abhaya Libre" !important;
  font-weight: 800;
  font-size: 52px;
  line-height: 56px;
  letter-spacing: 0%;
}

.affiliate-program-title-text {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0%;
}

.affiliate-program-description-text {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: left !important;
}

.affilate-program-item-title-text {
  margin-top: 1rem;
  font-family: "Poppins" !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
}

.affilate-program-item-description-text {
  color: #000;
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0%;
}

.get-in-touch-container {
  margin-top: 5rem;
  padding: 2rem;
  border-radius: 15px;
  background-color: #510000;
}

.get-in-touch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.get-in-touch-text-container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.get-in-touch-title-text {
  color: #fff !important;
  font-family: "Poppins" !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
}
.get-in-touch-text {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0%;

  color: #fff;
}

.get-in-touch-button {
  width: 20%;
  text-align: center;
}

.get-in-touch-btn {
  margin-left: 1rem;
  border-radius: 60px;
  border: 1px solid #510000;
  font-family: "Poppins" !important;
  color: #510000;
}

@media (max-width: 768px) {
  .header-text {
    font-size: 52px;
    line-height: 56px;
  }
}

@media (max-width: 576px) {
  .marginTop {
    margin-top: 0rem;
  }

  .affilate-program-section {
    gap: 3rem;
    grid-template-columns: 100%;
  }

  .header-text {
    font-size: 32px;
    line-height: 36px;
  }

  .about-our-affilate-program-header-text {
    font-family: "Nunito Sans !important", serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }

  .get-in-touch-container {
    margin: 3rem 0rem 0.1rem 0.1rem;
    padding: 2rem 1rem;
    border-radius: 15px;
    background-color: #510000;
  }

  .get-in-touch {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .get-in-touch-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .get-in-touch-text {
    font-family: "Poppins !important", serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    color: #fff;
  }

  .get-in-touch-button {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
  }
}
